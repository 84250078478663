import { useState } from "react";
import axios from "axios";

const contactDetails = {
  email: 'inquiry@bitslytech.com'
}

export function Contact({ privacyPolicy=false ,inquiry, setInquiry }) {
  const [firstname,setFirstname] = useState('')
  const [lastname,setLastname] = useState('')
  const [company,setCompany] = useState('')
  const [email,setEmail] = useState('')
  const [phone,setPhone] = useState('')
  const [message,setMessage] = useState('')
  const [agree,setAgree] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(privacyPolicy)
  const [btnMessage, setBtnMessage] = useState("Let's talk")

  const payload = {
    inquiry,
    firstname,
    lastname,
    company,
    email,
    phone,
    message,
    agree,
  }

  function handleReset(){
    setFirstname('')
    setLastname('')
    setCompany('')
    setEmail('')
    setPhone('')
    setMessage('')
    setBtnMessage("Let's talk")
  }

  function handleClosePrivacyPolicyModal(doAgree){
    setAgree(doAgree)
    setShowPrivacyPolicy(false)
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(false)
    setError(false)
    setAgree(false)
    setBtnMessage("Sending ...")

    console.log(payload);
    
    async function submitInquiry(){
      await axios.post(`/api/process_inquiry`, payload, {headers: "Content-Type: application/json"})
      .then(function (response) {
        console.log(response);
        console.log("Success!")
        document.querySelector('#inquireForm').reset()
        handleReset()
        setSubmitted(true)
      })
      .catch(function (error) {
        console.log(error);
        setAgree(false)
        setError(true)
        setBtnMessage("Let's talk")
      });
    }

    submitInquiry()
  }

  return (<div className='p-6 md:p-10 border-b-2' id="contact">
    <div className='section container m-auto'>

      <div className="isolate bg-white px-0 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-green-800 sm:text-4xl">Simplify? Get <span className='text-green-600'>BITSLY</span>fied!</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600 italic">
            Reach out to us today!</p>
        </div>
        <form action="#" method="POST" id="inquireForm" onSubmit={handleSubmit} className="mx-auto mt-16 max-w-xl sm:mt-20">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <label htmlFor="interest" className="block text-sm font-semibold leading-6 text-gray-900">Interest</label>
              <div className="mt-2.5">
                <input type="text" onChange={e => setInquiry(e.target.value)} value={inquiry} name="interest" id="interest" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div>
              <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">First name</label>
              <div className="mt-2.5">
                <input type="text" required onChange={e => setFirstname(e.target.value)} value={firstname} name="first-name" id="first-name" autoComplete="given-name" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div>
              <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">Last name</label>
              <div className="mt-2.5">
                <input type="text" required onChange={e => setLastname(e.target.value)} value={lastname} name="last-name" id="last-name" autoComplete="family-name" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">Company</label>
              <div className="mt-2.5">
                <input type="text" onChange={e => setCompany(e.target.value)} value={company} name="company" id="company" autoComplete="organization" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">Email</label>
              <div className="mt-2.5">
                <input type="email" required name="email" onInvalid={e=>console.log(e)} onChange={e => setEmail(e.target.value)} value={email} id="email" autoComplete="email" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">Phone number</label>
              <div className="relative mt-2.5">
                <input type="tel" onChange={e => {
                  return !isNaN(e.target.value) && setPhone(e.target.value)
                  }} value={phone} required name="phone-number" id="phone-number" autoComplete="tel" className="invalid:border-red-500 block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">Message</label>
              <div className="mt-2.5">
                <textarea name="message" required onChange={e => setMessage(e.target.value)} value={message} id="message" rows="4" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 p-3 gap-y-3'>
            <div className='flex gap-x-4 col-span-2'>
              <label className="text-sm leading-6 text-gray-600">
                By selecting this, you agree to our &nbsp;
                <a onClick={e=>{
                  e.preventDefault()
                  setShowPrivacyPolicy(true)
                }} href="#" className="font-semibold text-green-600">privacy&nbsp;policy</a>.
              </label>
            </div>
            <div className="flex gap-x-4 col-span-2">
              <div className="flex h-6">
                <label className="inline-flex items-center mb-0 cursor-pointer">
                  <input onChange={e=>setAgree(agree=> !agree)} type="checkbox" required value={agree} className="sr-only peer" id="agreeToPrivacyPolicy" checked={agree} />
                  <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                  <span className="ms-3 text-sm font-medium text-gray-400 dark:text-gray-500">I agree.</span>
                </label>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <button disabled={ !agree} type="submit" className={`${!agree ? 'disabled bg-gray-400' : 'bg-green-600 hover:bg-green-700 '} block w-full rounded-md  px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600`}>{btnMessage}</button>
          </div>
          { submitted && <SuccessMessage submitted={submitted} setSubmitted={setSubmitted} />}
          { error && <ErrorMessage error={error} setError={setError}/>}
        </form>
      </div>
      { showPrivacyPolicy && <PrivacyPolicy privacyPolicy={privacyPolicy} onClosePrivacyPolicyModal={handleClosePrivacyPolicyModal} /> }
    </div>

    
  </div>);
}

function SuccessMessage({submitted, setSubmitted}){
  return <div className={`px-3 py-4 bg-green-300 mt-5 rounded-md relative`}>
    <h2 className="my-1 block w-2/4"><span className=" text-4xl font-bold text-green-800">💬 Sent!</span></h2>
    <p className="text-black"> Thank you for reaching out to us. We have received your inquiry and will get back to you shortly. 🚀</p>
    <button type="button" onClick={e=>setSubmitted(false)} className="bg-green-800 hover:bg-green-600 text-green-200 rounded-full w-6 aspect-square absolute right-2 top-2">&times;</button>
  </div>
}

function ErrorMessage({error, setError}){
  return <div className={`px-3 py-4 bg-red-300 mt-5 rounded-md relative`}>
    <h2 className="my-1 block w-2/4"><span className=" text-4xl font-bold text-red-800">🤔 Failed</span></h2>
    <p className="text-black"> An error has occured while sending your inquiry. Please try again in a few moment. </p>
    <button type="button" onClick={e=>setError(false)} className="bg-red-800 hover:bg-red-600 text-red-200 rounded-full w-6 aspect-square absolute right-2 top-2">&times;</button>
  </div>
}

function PrivacyPolicy({ privacyPolicy, onClosePrivacyPolicyModal}){
  return <>
  <div className="overlay backdrop-blur-sm bg-gray-500 fixed hidden md:block top-0 left-0 right-0 bottom-0 opacity-70"></div>
  <div className="privacy-policy-main fixed top-0 md:top-20 left-0 md:left-20 right-0 md:right-20 bottom-0 md:bottom-20 bg-white border-2 border-gray-400 overflow-y-auto p-10 md:p-20 md:rounded-lg">
    <PrivacyPolicyContent  />

    <div className="grid grid-cols-2 gap-2 max-w-xl">
      {
        privacyPolicy ? // show back to home button
        <a href="/" className="p-3 bg-gray-200 rounded-md col-span-2"><i className="fa fa-chevron-left"/> Go back to home</a>
        : <>
          <button onClick={e=>onClosePrivacyPolicyModal(true)} className="p-3 bg-green-600 text-white rounded-sm">I Agree</button>
          <button onClick={e=>onClosePrivacyPolicyModal(false)} className="p-3 bg-gray-600 text-white rounded-sm">Go back</button>
        </>
      }
      
    </div>
  </div>
  </>
}

function PrivacyPolicyContent(){
  return (<>
  <h1 className="text-2xl font-bold">Privacy Policy</h1>
  <p><small>Last Updated: Aug 26, 2024</small></p>
  <p>At Bitsly I.T. Solutions, your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, and protect your personal information when you interact with us.</p>
  <h2>1. Information We Collect</h2>
  <p>We collect the following personal information when you communicate with us or submit an inquiry:</p>
  <ul>
    <li>First Name</li>
    <li>Last Name</li>
    <li>Email Address</li>
    <li>Contact Number</li>
    <li>Company Name</li>
  </ul>

  <h2>2. Purpose of Collection</h2>
  <p>The information we collect is solely for the purposes of:</p>
  <ul>
    <li>Communicating with you</li>
    <li>Responding to your inquiries</li>
    <li>Providing updates and information about our services</li>
  </ul>

  <h2>3. How We Use Your Information</h2>
  <p>Your personal information will be used exclusively to:</p>
  <ul>
    <li>Respond to your inquiries and provide the necessary support</li>
    <li>Communicate with you regarding our services and offerings</li>
    <li>Improve our services based on your feedback</li>
  </ul>

  <h2>4. Information Sharing</h2>
  <p>Bitsly I.T. Solutions does not sell, trade, or otherwise transfer your personal information to outside parties. Your information is only shared with trusted partners who assist us in operating our website and conducting our business, as long as they agree to keep this information confidential.</p>

  <h2>5. Data Security</h2>
  <p>We implement a variety of security measures to maintain the safety of your personal information. Your information is stored securely and only accessible by authorized personnel.</p>

  <h2 id="DataDeletionRequest">6. Your Rights</h2>
  <p>You have the right to:</p>
  <ul>
    <li>Request for the copy of all personal information we hold about you</li>
    <li>Request the correction of inaccurate information</li>
    <li>Request the deletion of your personal information</li>
    <li>Opt-out of any future communications</li>
  </ul>
  <p>To exercise these rights, please contact us at <a className="text-blue-400" href={`mailto:${contactDetails.email}`}>{contactDetails.email}</a>.</p>

  <h2>7. Changes to This Privacy Policy</h2>
  <p>Bitsly I.T. Solutions reserves the right to update this Privacy Policy at any time. Any changes will be posted on this page with an updated revision date.</p>

  <h2>8. Contact Us</h2>
  <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
  <h3>Bitsly I.T. Solutions</h3>
  <p>Email: <a className="text-blue-400" href={`mailto:${contactDetails.email}`}>{contactDetails.email}</a></p>
  </>)
}